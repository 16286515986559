export const CONFIGURATOR_DERIVATES: string[] = [
    'AM003',
    'AM370',
    'AM372',
    'AM382',
    'AM546',
    'AM576',
    'AM616',
    'AM646',
    'AM888'
];

export const VISUALIZER_DERIVATES: string[] = [
    'AM003',
    'AM370',
    'AM545',
    'AM546',
    'AM575',
    'AM576',
    'AM614',
    'AM616',
    'AM646',
    'AM888',
    'AMR003',
    'AMR24'
];

export const BIKE_DERIVATE_CODE = 'LVR';

export const CAP_CODES: Record<string, string> = {
    AM510: '98927',
    AM540: '98926',
    AM570: '98925',
    AM608: '82895',
    AM608F: '98024',
    AM638: '92845',
    AM638F: '98025',
    AM706: '98923',
    AM736: '98924',
    AM804: '92165',
    AM884: '100185',
    AM806: '92165',
    AM886: '100185'
};

export const MODELCODES: Record<string, string[]> = {
    AMR: ['AMR003', 'AMR23', 'AMR24'],
    LVR: ['LVR'],
    M200: [
        'AM544',
        'AM545',
        'AM546',
        'AM547',
        'AM574',
        'AM575',
        'AM576',
        'AM577'
    ],
    M210: [
        'AM606',
        'AM606F',
        'AM636',
        'AM636F',
        'AM608',
        'AM608F',
        'AM638',
        'AM638F',
        'AM668',
        'AM688',
        'AM612',
        'AM642',
        'AM614',
        'AM616',
        'AM644',
        'AM646'
    ],
    M212: ['AM690'],
    M220: ['AM705', 'AM735', 'AM706', 'AM736', 'AM709', 'AM739'],
    M222: ['AM370', 'AM372', 'AM382'],
    M230: [
        'AM802',
        'AM804',
        'AM806',
        'AM808',
        'AM862',
        'AM864',
        'AM866',
        'AM884',
        'AM886',
        'AM888'
    ],
    M255: ['AM003']
};

/**
 * Mapping from legacy model-family codes to new M-based codes.
 */
export const LEGACY_MODEL_FAMILY_MAPPING: Record<string, string> = {
    AM5: 'M200',
    AM6: 'M210',
    AM7: 'M220',
    AM8: 'M230',
    VALOUR: 'M212'
};
